import React, { useRef } from 'react';
import './DriverPartner.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faFileAlt, faCamera, faCar, faUser } from '@fortawesome/free-solid-svg-icons';

const DriverPartner = () => {
    const navigate = useNavigate();

    // Refs for input elements
    const fileInputs = {
        vehicleRC: useRef(null),
        drivingLicence: useRef(null),
        drivingLicenceNumber: useRef(null),
        aadharCard: useRef(null),
        profilePhoto: useRef(null),
    };

    // List of document requirements with ref keys
    const documentList = [
        { label: 'Vehicle RC', subLabel: 'Upload Vehicle RC', icon: faCar, refKey: 'vehicleRC' },
        { label: 'Driving Licence', subLabel: 'Upload Driving Licence', icon: faIdCard, refKey: 'drivingLicence' },
        { label: 'Driving Licence Number', subLabel: 'Enter Details', icon: faFileAlt, refKey: 'drivingLicenceNumber' },
        { label: 'Aadhar Card', subLabel: 'Upload Aadhar Card', icon: faIdCard, refKey: 'aadharCard' },
        { label: 'Profile Photo', subLabel: 'Upload Profile Photo', icon: faCamera, refKey: 'profilePhoto' },
    ];

    // Handle file input click
    const handleDocumentClick = (refKey) => {
        fileInputs[refKey].current.click();
    };

    // Handle file change
    const handleFileChange = (event, docLabel) => {
        const file = event.target.files[0];
        if (file) {
            alert(`File selected for ${docLabel}: ${file.name}`);
        }
    };

    const handleUpload = () => {
        alert('Document uploading process started.');
    };

    const handleCancel = () => {
        navigate('/home');
    };

    return (
        <div className="driver-partner-container">
            {/* Header */}
            <div className="header-partner">
                <h2 style={{ color: 'white', textAlign: 'center' }}>Upload Your Documents</h2>
            </div>

            {/* Document Upload Section */}
            <div className="upload-section">
                <h3>Upload documents</h3>
                <p>Keep all these documents handy</p>
                <ul className="document-list">
                    {documentList.map((doc, index) => (
                        <li key={index} className="document-item" onClick={() => handleDocumentClick(doc.refKey)}>
                            <div className="doc-info">
                                <FontAwesomeIcon icon={doc.icon} className="doc-icon" />
                                <div className="doc-text">
                                    <h4>{doc.label}</h4>
                                    <p>{doc.subLabel}</p>
                                </div>
                            </div>
                            {/* Hidden file input */}
                            <input
                                type="file"
                                ref={fileInputs[doc.refKey]}
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileChange(e, doc.label)}
                                accept="image/*,application/pdf"
                            />
                        </li>
                    ))}
                </ul>
            </div>

            {/* Upload and Cancel Buttons */}
            <div className="action-buttons">
                <button className="upload-btn" onClick={handleUpload}>Start Upload</button>
                <button className="cancel-btn" onClick={handleCancel}>Cancel Application</button>
            </div>
        </div>
    );
};

export default DriverPartner;
