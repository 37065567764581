import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendOtp, verifyOtp, updateProfile as updateProfileAPI } from '../../api'; // You need to implement these API calls




// Thunk to send OTP
export const sendOtpCode = createAsyncThunk('auth/sendOtpCode', async (phone, { rejectWithValue }) => {
    try {
        const response = await sendOtp({ phone }); // Call sendOtp API
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'OTP sending failed');
    }
});

// Thunk to verify OTP
export const verifyOtpCode = createAsyncThunk('auth/verifyOtpCode', async (credentials, { rejectWithValue }) => {
    try {
        const response = await verifyOtp(credentials); // Call verifyOtp API with phone and OTP
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'OTP verification failed');
        }
});

// Thunk to update profile
export const updateProfile = createAsyncThunk(
    'auth/updateProfile',
    async (profileData, { getState, rejectWithValue }) => {
        const { token } = getState().auth; // Access the token from Redux state
        try {
            const response = await updateProfileAPI(profileData, token);
            return response; // Expected to return updated user data
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || 'Profile update failed'
            );
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        user: null,
        status: 'idle',
        error: null,
        message: null,
    },
    reducers: {
        logout: (state) => {
            state.token = null;
            state.user = null;
            state.status = 'idle';
            state.error = null;
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // OTP send cases
            .addCase(sendOtpCode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(sendOtpCode.fulfilled, (state) => {
                state.status = 'otp_sent';
                state.message = 'OTP sent successfully';
                state.error = null;
            })
            .addCase(sendOtpCode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'OTP sending failed';
            })
            // OTP verify cases
            .addCase(verifyOtpCode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(verifyOtpCode.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.message = 'Login successful'; // Set success message for login
                state.error = null;
            })
            .addCase(verifyOtpCode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'OTP verification failed';
            })

            .addCase(updateProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user; // Update the user profile in state
                state.message = 'Profile updated successfully';
                state.error = null;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Profile update failed';
            });
    },
});


export const { logout } = authSlice.actions;
export default authSlice.reducer;
