import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { updateProfile } from '../../features/auth/authSlice'; // Adjust the path based on your folder structure
import './Profile.css'; // Ensure this file includes styling for the new edit functionality

const Profile = () => {
    const dispatch = useDispatch();
    const { user, status, error, message } = useSelector((state) => state.auth);

    // Local state for edit mode and form data
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        name: user?.name || '',
        email: user?.email || '',
        phone: user?.phone || '',
    });

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    // Function to handle back navigation
    const handleBackClick = () => {
        window.history.back(); // Navigate back to the previous page or handle back action
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateProfile(formData));
        setIsEditing(false); // Exit edit mode after saving
    };

    return (
        <div className="profile-container">
            {/* Header */}
            <div className="profile-header">
                <Link to="/" className="back-arrow">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
            </div>

            {/* Profile Picture Section */}
            <div className="profile-picture-container">
                <div className="profile-icon">
                    <img src="/assets/img/avatar.png" alt="Profile" />
                </div>
                <button
                    className="edit-profile-btn"
                    onClick={() => setIsEditing(!isEditing)}
                >
                    ✏️
                </button>
            </div>

            <h2>Profile Details</h2>
            <p>Let’s fill in the basic details of your profile</p>

            {/* Error and Success Messages */}
            {error && <p className="error">{error}</p>}
            {message && <p className="success">{message}</p>}

            {/* Form */}
            <form onSubmit={handleSubmit} className="profile-form">
                <div className="input-group">
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Name"
                        disabled={!isEditing}
                    />
                </div>

                <div className="input-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email Address"
                        disabled={!isEditing}
                    />
                </div>

                <div className="input-group">
                    <label>Phone Number</label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="Phone Number"
                        disabled={!isEditing}
                    />
                </div>

                {/* Buttons */}
                <div className="action-buttons">
                    {isEditing ? (
                        <>
                            <button
                                type="button"
                                className="cancel-button"
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="proceed-button">
                                Save
                            </button>
                        </>
                    ) : (
                        <button
                            type="button"
                            className="proceed-button"
                            onClick={() => setIsEditing(true)}
                        >
                            Edit
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default Profile;
