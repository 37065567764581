import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import './Slider1.css';

const Slider1 = () => {
  const [amount, setAmount] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);
  const upiId = '008210345655@ybl';
  const payeeName = 'EasyRides';

  const handlePayment = (app) => {
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount.');
      return;
    }

    const upiUrl = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
      payeeName
    )}&tn=Payment%20for%20EasyRides&am=${amount}&cu=INR`;

    let deepLink;
    switch (app) {
      case 'googlePay':
        deepLink = `intent://pay?pa=${upiId}&pn=${encodeURIComponent(
          payeeName
        )}&am=${amount}&cu=INR&url=${encodeURIComponent(
          upiUrl
        )}#Intent;scheme=upi;package=com.google.android.apps.nbu.paisa.user;end`;
        break;
      case 'phonePe':
        deepLink = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
          payeeName
        )}&tn=Payment%20for%20EasyRides&am=${amount}&cu=INR&mode=00&purpose=00`;
        break;
      case 'paytm':
        deepLink = `paytmmp://pay?pa=${upiId}&pn=${encodeURIComponent(
          payeeName
        )}&am=${amount}&cu=INR`;
        break;
      case 'bhim':
        deepLink = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
          payeeName
        )}&am=${amount}&cu=INR`;
        break;
      default:
        deepLink = upiUrl;
        break;
    }

    window.location.href = deepLink;
  };

  const toggleQRCode = () => {
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount.');
      return;
    }
    setShowQRCode(!showQRCode);
  };

  const qrCodeUrl = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
    payeeName
  )}&am=${amount}&cu=INR&tn=Payment%20for%20EasyRides`;

  return (
    <div className="payment-options-page">
      <h2>Make a Payment</h2>
      <div className="input-container">
        <input
          type="number"
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="amount-input"
          min="1"
        />
      </div>
      <div className="icons-container">
        <button className="pay-icon google-pay" onClick={() => handlePayment('googlePay')}>
          <img src="/assets/img/icons/google-pay.png" alt="Google Pay" className="icon" />
        </button>
        <button className="pay-icon phonepe" onClick={() => handlePayment('phonePe')}>
          <img src="/assets/img/icons/phonepe.png" alt="PhonePe" className="icon" />
        </button>
        <button className="pay-icon paytm" onClick={() => handlePayment('paytm')}>
          <img src="/assets/img/icons/paytm.png" alt="Paytm" className="icon" />
        </button>
        <button className="pay-icon bhim" onClick={() => handlePayment('bhim')}>
          <img src="/assets/img/icons/bhim.png" alt="BHIM" className="icon" />
        </button>
      </div>
      <button className="qr-code-button" onClick={toggleQRCode}>
        {showQRCode ? 'Hide QR Code' : 'Show QR Code'}
      </button>
      {showQRCode && (
        <div className="qr-code-container">
          <QRCodeSVG value={qrCodeUrl} size={180} includeMargin={true} />
          <p>Scan to Pay via UPI</p>
        </div>
      )}
    </div>
  );
};

export default Slider1;
